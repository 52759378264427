import React, { useState, useEffect } from 'react';
import APIClient from '../../lib/APIClient';

const RecruiterMapping = ({ authToken }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recruiters, setRecruiters] = useState([]);
  const [availableAtsUsers, setAvailableAtsUsers] = useState([]);
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await APIClient.fetchAtsUsers(authToken);
      if (response.ok) {
        const data = await response.json();
        setRecruiters(data.recruiters);
        setAvailableAtsUsers(data.available_ats_users);
      } else {
        setError('Failed to fetch users');
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async (mogulEmail, atsUserEmail) => {
    setConnecting(true);
    try {
      const response = await APIClient.connectAtsUser(authToken, mogulEmail, atsUserEmail);
      
      if (response.ok) {
        fetchUsers();
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to connect user');
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setConnecting(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="recruiter-mapping-container">
      <table className="table">
        <thead>
          <tr>
            <th>Mogul recruiter email</th>
            <th>
              ATS recruiter email
              {connecting && <span> Loading...</span>}
            </th>
          </tr>
        </thead>
        <tbody>
          {recruiters.map(({ mogul_email, ats_user_email }) => {
            return (
              <tr key={mogul_email}>
                <td>{mogul_email}</td>
                <td>
                  <select 
                    value={ats_user_email || ''}
                    onChange={(e) => handleConnect(mogul_email, e.target.value)}
                    disabled={connecting}
                  >
                    {!ats_user_email && <option value="">Select corresponding email</option>}
                    {ats_user_email && <option value={ats_user_email}>{ats_user_email}</option>}
                    {availableAtsUsers
                      .filter(ats_user => !recruiters.some(r => 
                        r.ats_user_email === ats_user.email && r.mogul_email !== mogul_email
                      ))
                      .map(ats_user => (
                        <option 
                          key={ats_user.id} 
                          value={ats_user.email}
                          disabled={ats_user_email !== null}
                        >
                          {ats_user.email}
                        </option>
                      ))
                    }
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RecruiterMapping; 
