import React, { useState, useEffect } from "react";
import APIClient from '../../lib/APIClient';

const StageMapping = ({ authToken }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stages, setStages] = useState([]);
  const [mappedStages, setMappedStages] = useState({});
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    fetchStages();
  }, []);

  const fetchStages = async () => {
    try {
      const response = await APIClient.fetchAtsStages(authToken);
      if (response.ok) {
        const data = await response.json();
        setStages(data.stages);
        const formattedData = formatStagesResponse(data.mapped_stages);
        setMappedStages(formattedData || {});
      } else {
        setError('Failed to fetch stages');
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const formatStagesResponse = (mappedStages) => {
    const formattedData = {};

    Object.values(mappedStages).forEach(stage => {
      formattedData[stage.stage_type] = stage.stage_id;
    });
    
    return formattedData;
  }

  const handleStageConnect = async (stageType, stageId) => {
    setConnecting(true);
    try {
      const response = await APIClient.connectAtsStage(authToken, stageType, stageId);
      
      if (response.ok) {
        setMappedStages(prev => ({
          ...prev,
          [stageType]: stageId
        }));
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to connect stage');
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setConnecting(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="stage-mapping-container">
      <div className="stage-select">
        <label>Name of hired stage</label>
        <select 
          value={mappedStages.hired || ''}
          onChange={(e) => handleStageConnect('hired', e.target.value)}
          disabled={connecting}
        >
          {stages.map(stage => (
            <option 
              key={stage.id} 
              value={stage.id}
              disabled={Object.values(mappedStages).includes(stage.id) && stage.id !== mappedStages.hired}
            >
              {stage.name}
            </option>
          ))}
        </select>
      </div>
      
      <div className="stage-select">
        <label>Name of offer made stage</label>
        <select 
          value={mappedStages.offer || ''}
          onChange={(e) => handleStageConnect('offer', e.target.value)}
          disabled={connecting}
        >
          {stages.map(stage => (
            <option 
              key={stage.id} 
              value={stage.id}
              disabled={Object.values(mappedStages).includes(stage.id) && stage.id !== mappedStages.offer}
            >
              {stage.name}
            </option>
          ))}
        </select>
      </div>

      {connecting && <div className="loading-indicator">Updating...</div>}
    </div>
  );
};

export default StageMapping; 
