import React, { useState } from "react";
import APIClient from "../../lib/APIClient";
import ConnectionStatus from "./ConnectionStatus";
import StageMapping from "./StageMapping";
import RecruiterMapping from "./RecruiterMapping";

const AtsSettingsContainer = ({ atsConnection, authToken }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDisconnectAts = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await APIClient.disconnectAts(authToken);

      if (response.ok) {
        window.dispatchEvent(new CustomEvent("atsStatusChanged"));
        window.location.reload();
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to disconnect ATS.");
        console.error("Error disconnecting ATS:", response);
      } 
    } catch (err) {
      setError("An unexpected error occurred while disconnecting ATS.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ats-settings">
      {error && <p style={{ color: "red" }}>{error}</p>}
      
      <h1>ATS Settings</h1>
      
      <section className="ats-connection">
        <div className="section-header">
          <h2>ATS connection</h2>
        </div>
        <div className="section-content">
          <ConnectionStatus 
            atsConnection={atsConnection}
            onDisconnect={handleDisconnectAts}
            loading={loading}
          />
        </div>
      </section>
      { atsConnection.syncingStatus === 'synced' ? (
        <div>
          <section className="stages-section">
            <div className="section-header">
            <h2>Offer and Hired Stages</h2>
            <p className="text-muted">
              Select the offer and hired stages on your ATS
            </p>
          </div>
          <div className="section-content">
            <StageMapping authToken={authToken} />
            </div>
          </section>

        <section className="recruiter-mapping">
          <div className="section-header">
            <h2>User → Recruiter Association</h2>
            <p className="text-muted">
              Select corresponding ATS user with their Mogul account.
            </p>
            <p className="text-muted">
              Not selecting a corresponding email won't allow Mogul user to sync their work with the ATS
            </p>
            </div>
            <div className="section-content">
              <RecruiterMapping authToken={authToken} />
            </div>
          </section>
        </div>
      ) : atsConnection.syncingStatus === 'sync_failed' ? (
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "red" }}>
            There was an error syncing with your ATS. Please resolve the listed issues.
          </p>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <p>
            Your ATS is syncing. Please check back soon.
          </p>
        </div>
      )}
    </div>
  );
};

export default AtsSettingsContainer; 
