import React, { useState, useEffect, useRef } from "react";
import { useMergeLink } from "@mergeapi/react-merge-link";
import APIClient from "../../lib/APIClient";

const AtsConnectionContainer = ({ authToken }) => {
  const [atsConnection, setAtsConnection] = useState(null);
  const [linkToken, setLinkToken] = useState(null);
  const [canManageAts, setCanManageAts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAtsConnection();
  }, []);

  const openTriggeredRef = useRef(false);

  const fetchAtsConnection = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await APIClient.fetchAtsConnection(authToken);
      if (response.ok) {
        const data = await response.json();
        setAtsConnection(data.ats_connection);
        setLinkToken(data.link_token);
        setCanManageAts(data.can_manage_ats);
      } else {
        const errorData = await response.json();
        setError("Failed to fetch ATS connection.");
      }

      setLoading(false);
    } catch (error) {
      setError("An unexpected error occurred while fetching ATS connection.");
      console.error("Error fetching ATS connection:", error);
    }
  };

  const onSuccess = async (publicToken) => {
    setLoading(true);
    setError(null);
    try {
      const response = await APIClient.createAtsConnection(authToken, publicToken);
      
      if (response.ok) {
        const data = await response.json();
        setAtsConnection(data.ats_connection);
        setLinkToken(data.link_token);
        dispatchAtsStatusEvent();
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to create ATS connection.");
      }
    } catch (error) {
      setError("An unexpected error occurred while creating ATS connection.");
      console.error("Error creating ATS connection:", error);
    } finally {
      setLoading(false);
    }
  };

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess,
    onExit: () => {
      if (!atsConnection) {
        // important for refreshing the link token
        fetchAtsConnection();
      } else {
        window.location.reload();
      }
    }
  });

  useEffect(() => {
    if (isReady && linkToken && canManageAts && !atsConnection && !openTriggeredRef.current) {
      open();
      openTriggeredRef.current = true;
    }
  }, [isReady, linkToken, canManageAts, atsConnection]);

  const dispatchAtsStatusEvent = () => {
    const event = new CustomEvent("atsStatusChanged")

    window.dispatchEvent(event);
  };  

  const atsStatus = atsConnection ? atsConnection.status : null;

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}

      { loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <p>Loading...</p> 
        </div>
      ) : (
        atsStatus === "connected" ? (
          <div>
          </div>
        ) : ( 
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
            <button className="button" disabled={!isReady || !canManageAts} onClick={open}>Connect to ATS</button>
          </div>
        )
      )}
    </div>
  );
};

export default AtsConnectionContainer;
