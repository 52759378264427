import React from "react";

const ConnectionStatus = ({ atsConnection, onDisconnect, loading }) => {
  const connected = atsConnection?.status === "connected"
  const error = atsConnection?.syncingStatus === "sync_failed"
  const atsIssues = atsConnection?.atsIssues || []
  const atsLogo = atsConnection?.logoUrl
  
  return (
    <div>
      <div className="connection-card">
        <div className="status-icon">
          { connected && !error && atsLogo && <img className="ats-logo" src={atsLogo} alt="ATS Logo" /> }
        </div>
        <div className="status-text">
          { error 
            ? <div className="error-text">
                <span style={{ fontWeight: "bold" }}>There was an error syncing with your ATS.</span>
                { atsIssues.length > 0 && (
                  <div className="error-details">
                    { atsIssues.map(issueObject => (
                      <div key={issueObject.id} style={{ marginTop: "15px" }}>
                        <h4 style={{ margin: "5px 0" }}>{issueObject.errorDescription}</h4>
                        <ul style={{ margin: "5px 0", color: "red" }}>
                          {issueObject.errorDetails.map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            : connected 
              ? `${atsConnection.atsName} is connected` 
              : "Not connected"
          }
        </div>
      </div>
      {loading && <span>Loading...</span>}
      {atsConnection && !loading && (
        <a href="#" style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px", fontSize: "14px"}} className="disconnect-link" onClick={onDisconnect}>
          Disconnect my ATS
        </a>
      )}
    </div>
  );
};

export default ConnectionStatus; 
